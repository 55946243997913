import React from 'react';

import { CommonLayout } from '../../components/Layout/CommonLayout';
import { MainScreen, TransformationsScreen, IntegrationScreen } from '../../components/forCDN';
import { PlatformScreen } from '../../components/PlatformScreen';
import { FinalCTA } from '../../components/FinalCTA';
import { ClientsCases } from '../../components/ClientsCases';
import { frontmatter as originalFrontmatter } from '../cdn/index';
import { Navbar, NavbarModesEnum } from '../../components/Navbar';

export const frontmatter = {
  canonicalUrl: 'https://uploadcare.com/cdn/',
  noindex: true,
};

const SmartCDN = ({ location }) => {
  return (
    <CommonLayout
      meta={{
        ...originalFrontmatter,
        ...frontmatter,
      }}
      pathName={location.pathname}
    >
      <Navbar mode={NavbarModesEnum.PPC} />
      <MainScreen ppc />
      <TransformationsScreen ppc />
      <IntegrationScreen ppc />
      <PlatformScreen heading="The edge of an entire file handling system" />
      <ClientsCases />
      <FinalCTA ppc />
    </CommonLayout>
  );
};

export default SmartCDN;
